.faq-wrapper {
  margin: 60px 0;

  .headerbox {
    background: #ff0066;
    padding: 18px;
    color: #fff;

    h3 {
      font-weight: 700;
      font-size: 25px;
      margin: 0;
    }
    span {
      font-weight: 500;
      font-size: 18px;
    }
  }

  .card-header {
    background-color: white;
  }

  .panel-title {
    margin: 0;
  }
  .panel-title > a,
  .panel-title > a:active {
    display: block;
    padding: 5px 0;
    color: #333;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    word-spacing: 3px;
    text-decoration: none;
  }
  .panel-title a:before {
    font-family: FontAwesome;
    content: '\f107';
    float: right;
    transition: all 0.5s;
  }

  .panel-group .panel + .panel {
    margin-top: 10px;
  }
  .panel-group .panel {
    border-radius: 0;
    background: #fff;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  .panel-body {
    padding: 0 25px 20px;
  }
  .center-block {
    border: 1px solid #dedede;
    margin-bottom: 60px;
  }
  .panel-group {
    margin-bottom: 0;
  }
  .panel-body ul li {
    padding: 10px 0;
  }
  .panel-body p {
    padding-left: 40px;
  }
  img {
    width: 100%;
  }
}
