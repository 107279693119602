body {
  background: rgb(238, 238, 238) !important;
}

.navbar {
  background-color: rgb(37, 37, 37);
}

.btn.btn-danger {
  background-color: #ff0066 !important;
  border-color: #ff0066 !important;
}

.btn.btn-rounded {
  border-radius: 10em;
}

.btn.btn-sm {
  font-size: 0.8rem;
  line-height: 1;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  border: 1px solid #ced4da;
  outline: 0;
  border-radius: 4px;
  background: white;
}
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}
